import http from "./baseApi"

export default {
  getCountries(company_id) {
    return http.post("ws/countries", {
      company_id,
    }) 
  },
  getProvinces(company_id, country_id) {
    return http.post("/ws/country_provinces", {
      company_id,
      country_id
    })
  },
  getAtms(company_id, cashout_id) {
    return http.post("/ws/cashiers_country_provinces", {
      company_id,
      cashout_id
    })
  },

  getAplication(aplication) {
    return http.post("/loan/loan_credit_application", aplication)
  },

  confirmNew(detail) {
    return http.post("/loan/loan_confirm", detail)
  },

  async getMeansWithdrawals(company_id) {
    return http.post("/loan/loan_means_withdrawal", {
      company_id
    })
  },

  async getGiftcards(company_id, client_id, wallet_currency_id, wallet_id) {
    return http.post("/loan/list_cashout_type", {
      company_id,
      client_id,
      wallet_currency_id,
      wallet_id
    })
  },

  getActiveGiftcardData(
    company_id,
    client_id,
    wallet_currency_id,
    wallet_id,
    operation_id
  ) {
    return http.post("/loan/active_giftcard", {
      company_id,
      client_id,
      wallet_currency_id,
      wallet_id,
      operation_id
    })
  },

  getGiftcardDataToTake(
    company_id,
    client_id,
    cashout_id,
    cashout_type_id,
    wallet_currency_id,
    wallet_id
  ) {
    return http.post("/loan/credit_data_to_take", {
      company_id,
      client_id,
      cashout_id,
      cashout_type_id,
      wallet_currency_id,
      wallet_id
    })
  },

  getActiveGiftcardDetail(company_id, operation_id) {
    return http.post("/loan/loan_credit_detail", {
      company_id,
      operation_id
    })
  },

  getGiftcardBankData(company_id, client_id, cashout_id, cashout_type_id) {
    // llamar endpoint cashout_bank_info
    return http.post("cashout_bank_info", {
      company_id,
      client_id,
      cashout_id,
      cashout_type_id
    })
    // return {
    //   data: {
    //     title: "Soy el titulo mock del modal",
    //     subtitle: "Soy el subtitulo!",
    //     body: "<p>Soy un parrafo body del modal</p>"
    //   }
    // }
  }
}
