<template>
  <b-card class="text-center standar-card-bg full-width min-height-debit-toc">
    <b-card-text>
      <b-container class="" fluid align-content="center">
        <b-row fluid class="pb-4"
          ><b-col
            ><span class="title-debit-card-wizard"
              >Complete los siguientes datos tal como figuran en su documento de
              identidad</span
            ></b-col
          ></b-row
        >
        <template v-if="firstLocalStep">
          <b-row fluid class="pb-4 text-left">
            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Nombre</span>
                <letter-and-space-input
                  v-model="form.name"
                  :placeholder="'Nombre'"
                ></letter-and-space-input>
                <span class="form-help form-error" v-if="$v.form.name.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Apellido</span>
                <letter-and-space-input
                  v-model="form.surname"
                  :placeholder="'Apellido'"
                ></letter-and-space-input>
                <span
                  class="form-help form-error"
                  v-if="$v.form.surname.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">Documento de identidad</span>
                <b-form-input
                  v-model="form.cuit"
                  placeholder="Documento de identidad"
                />
                <span class="form-help form-error" v-if="$v.form.cuit.$invalid"
                  >Este campo es requerido, debe ser numérico y debe contener entre 5 y 9 digitos.</span
                >
              </span>
            </b-col>

            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">CBU</span>
                <b-form-input v-model="form.cbu" placeholder="CBU" />
                <span class="form-help form-error" v-if="$v.form.cbu.$invalid"
                  >Este campo es requerido y debe ser numérico</span
                >
              </span>
            </b-col>
            
            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">Género</span>
                <combo-box
                  @change="data => (form.gender = data.value)"
                  :items="generos"
                  placeholder="Género"
                />
                <span
                  class="form-help form-error"
                  v-if="$v.form.gender.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Fecha de Nacimiento</span>
                <b-form-input
                  type="date"
                  v-model="form.birth_date"
                  placeholder="Fecha de Nacimiento"
                />
                <span
                  class="form-help form-error"
                  v-if="$v.form.birth_date.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>


            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Teléfono</span>
                <b-form-input
                  v-model="form.phone_number"
                  placeholder="Teléfono"
                  no-wheel
                  maxLength="15"
                />
                <span
                  class="form-help form-error"
                  v-if="$v.form.phone_number.$invalid"
                  >Este campo es requerido y debe ser numérico</span
                >
              </span>
            </b-col>

            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">Email</span>
                <b-form-input
                  disabled
                  v-model="form.email"
                  placeholder="Email"
                />
                <span class="form-help form-error" v-if="$v.form.email.$invalid"
                  >Este campo es requerido y debe ser un mail válido</span
                >
              </span>
            </b-col>
          </b-row>

          <b-row fluid class="pt-4"
            ><b-col
              ><span class="boton-deposito"
                ><b-button
                  variant="bithan-button"
                  @click="goToNextStep"
                  :disabled="!validFirstLocalStep"
                  >SIGUIENTE</b-button
                ></span
              ></b-col
            ></b-row
          >
          <b-row fluid class="pt-4"
            ><b-col
              ><span class="boton-cancelar"
                ><b-button variant="bithan-link-button" @click="goToHome"
                  >CANCELAR</b-button
                ></span
              ></b-col
            ></b-row
          >
        </template>

        <template v-else>
          <b-row fluid class="pb-4 text-left">
            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">Nacionalidad</span>
                <combo-box
                  @change="changeCountry"
                  :items="paises"
                  :initial_text="form.country"
                  placeholder="Nacionalidad"
                />
                <span
                  class="form-help form-error"
                  v-if="$v.form.country.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">Provincia</span>
                <template v-if="provincias.length > 0">
                  <combo-box
                    @change="
                      data => {
                        form.state = data.text
                        form.province_id = data.id
                      }
                    "
                    :items="provincias"
                    placeholder="Provincia"
                    :initial_text="form.state"
                  />
                </template>
                <template v-else>
                  <b-form-input v-model="form.state" placeholder="Provincia" />
                </template>
                <span class="form-help form-error" v-if="$v.form.state.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Calle</span>
                <b-form-input v-model="form.street" placeholder="Calle" />
                <span
                  class="form-help form-error"
                  v-if="$v.form.street.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Altura</span>
                <b-form-input
                  v-model="form.street_number"
                  placeholder="Altura"
                />
                <span
                  class="form-help form-error"
                  v-if="$v.form.street_number.$invalid"
                  >Este campo es requerido y debe ser numérico</span
                >
              </span>
            </b-col>

            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Piso</span>
                <b-form-input
                  v-model="form.piso"
                  placeholder="Piso"
                />
                <span class="form-help form-error" v-if="$v.form.piso.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" md="6" class="pb-3">
              <span class="form">
                <span class="form-label">Departamento</span>
                <b-form-input
                  v-model="form.dpto"
                  placeholder="Departamento"
                />
                <span class="form-help form-error" v-if="$v.form.dpto.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="6" class="pb-3">
              <span class="form">
                <span class="form-label">Ciudad</span>
                <b-form-input v-model="form.city" placeholder="Ciudad" />
                <span class="form-help form-error" v-if="$v.form.city.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="6" class="pb-3">
              <span class="form">
                <span class="form-label">Código Postal</span>
                <b-form-input v-model="form.cp" placeholder="CP" />
                <span class="form-help form-error" v-if="$v.form.cp.$invalid"
                  >Este campo es requerido y debe ser numérico</span
                >
              </span>
            </b-col>

            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">Pais 6C</span>
                <combo-box
                  :clase-adicional="'six-clover-country'"
                  @change="data => (form.pais_six_clovers = data.name)"
                  :items="countries6c"
                  placeholder="Pais 6C"
                />
                <span
                  class="form-help form-error"
                  v-if="$v.form.pais_six_clovers.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>

            <b-col cols="12" class="pb-3">
              <span class="form">
                <span class="form-label">Nacionalidad 6C</span>
                <combo-box
                  :clase-adicional="'six-clover-country'"
                  @change="changeNacionalidad6c"
                  :items="countries6c"
                  placeholder="Nacionalidad 6C"
                />
                <span
                  class="form-help form-error"
                  v-if="$v.form.nacionalidad_six_clovers.$invalid"
                  >Este campo es requerido</span
                >
              </span>
            </b-col>
          </b-row>
          <b-row fluid class="pt-4"
            ><b-col
              ><span class="boton-deposito"
                ><b-button
                  variant="bithan-button"
                  @click="goToDebitResume"
                  :disabled="$v.$invalid"
                  >SIGUIENTE</b-button
                ></span
              ></b-col
            ></b-row
          >
          <b-row fluid class="pt-4"
            ><b-col
              ><span class="boton-cancelar"
                ><b-button variant="bithan-link-button" @click="goToHome"
                  >CANCELAR</b-button
                ></span
              ></b-col
            ></b-row
          >
        </template>
        <b-col class="pt-4">
          <stepper :filled="currentStep" :size="maxSteps" />
        </b-col>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import giftcardsService from "@/services/giftcardsService"
import { required, numeric, email, minLength, maxLength } from "vuelidate/lib/validators"

import EventBus from "@/utils/EventBus"
import _ from "lodash"
import onboardingService from "@/services/onboardingService"
import { mapGetters } from "vuex"
import stepsMixin from "@/mixins/stepsMixin"

export default {
  name: "DebitForm",
  mixins: [stepsMixin],
  components: {
    Stepper: () => import("@/views/Components/Giftcards/Stepper"),
    ComboBox: () => import("@/views/Components/Giftcards/ComboBox"),
    LetterAndSpaceInput: () => import("@/components/inputs/LetterAndSpaceInput")
  },
  computed: {
    ...mapGetters("user", [
      "email",
      'routeByOrigin',
      'shouldReload'
    ]),
    validFirstLocalStep() {
      return !(
        this.$v.form.email.$invalid ||
        this.$v.form.name.$invalid ||
        this.$v.form.surname.$invalid ||
        this.$v.form.cuit.$invalid ||
        this.$v.form.cbu.$invalid ||
        this.$v.form.birth_date.$invalid ||
        this.$v.form.gender.$invalid ||
        this.$v.form.phone_number.$invalid
      )
    }
  },
  data() {
    return {
      user_data: {},
      paises: [],
      provincias: [],
      generos: [
        { value: "M", text: "Masculino" },
        { value: "F", text: "Femenino" }
      ],
      countries6c: [],
      form: {
        name: "",
        surname: "",
        cuit: "",
        birth_date: "",
        gender: "",
        phone_number: "",
        email: "",
        country: "",
        street: "",
        street_number: "",
        dpto: "",
        piso: "",
        cp: "",
        state: "",
        city: "",
        country_id: "",
        province_id: "0",
        cbu: "",
        pais_six_clovers: "",
        nacionalidad_six_clovers: ""
      },
      firstLocalStep: true
    }
  },
  async mounted() {
    onboardingService.getPaisesSixClovers().then(response => {
      this.countries6c = response.data.countries.map(c => {
        return { name: c.code, text: c.description }
      })
    })
    EventBus.$emit("loading", true)
    await this.loadForm()
    this.form.email = this.email
  },
  methods: {
    goToDebitResume() {
      this.$emit("confirm-step", { data: this.form })
    },
    goToHome() {
      // this.$router.push("/")
      if(this.shouldReload) {
        location.reload();
        return
      }

      this.$router.push({
        name: this.routeByOrigin
      }).catch(()=>{});
    },
    async loadForm() {
      const respPaises = await giftcardsService.getCountries(this.company_id)

      if (respPaises.data.error === 1) {
        alert(respPaises.data.message)
      }
      this.paises = respPaises.data.list_countries.map(item => {
        return { id: item.country_id, text: item.country_name }
      })

      // I have to take a country_id for passing to ws and get provinces
      // I choose first of contries and then try yo get if user has one on it's data
      let country_id = this.paises[0].id

      if (this.user_data.country_name) {
        const actual_user_country = this.paises.find(
          item => item.text === this.user_data.country_name
        )
        if (actual_user_country) {
          country_id = actual_user_country.id
        }
      }

      await this.loadProvinces(country_id)

      EventBus.$emit("loading", false)
    },
    async loadProvinces(country_id) {
      const resp = await giftcardsService.getProvinces(
        this.company_id,
        country_id
      )
      if (resp.data.error === 1) {
        alert(resp.data.message)
        this.goToHome()
      }
      const provinces = await resp.data.list_provinces.map(item => {
        return { id: item.province_id, text: item.province_name }
      })
      this.provincias = provinces

      return provinces
    },
    async changeCountry(data) {
      this.form.country_id = data.id
      this.form.country = data.text

      const provincias = _.clone(await this.loadProvinces(data.id))
      const province = provincias.find(
        p => p.text === this.user_data.province_name
      )
      const currentProvince = _.clone(province)

      if (currentProvince) {
        this.form.state = currentProvince.text
        this.form.province_id = currentProvince.id
      } else if (
        provincias.length === 0 &&
        this.form.state &&
        this.form.province_id === "0"
      ) {
      } else {
        this.form.state = ""
        this.form.province_id = "0"
      }
    },
    changeCountry6c(data) {
      const oldForm = this.form
      oldForm.pais_six_clovers = data.name
      this.form = oldForm
    },
    changeNacionalidad6c(data) {
      const oldForm = this.form
      oldForm.nacionalidad_six_clovers = data.name
      this.form = oldForm
    },
    goToNextStep() {
      this.firstLocalStep = false
      this.form.state = ""
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      surname: {
        required
      },
      cuit: {
        required,
        numeric,
        minLength: minLength(5), 
        maxLength: maxLength(9)
      },
      birth_date: {
        required
      },
      gender: {
        required
      },
      phone_number: {
        required
      },
      email: {
        required,
        email
      },
      country: {
        required
      },
      pais_six_clovers: {
        required
      },
      nacionalidad_six_clovers: {
        required
      },
      street: {
        required
      },
      street_number: {
        required,
        numeric
      },
      dpto: {},
      piso: {},
      cp: {
        required,
        numeric
      },
      cbu: {
        required,
        numeric
      },
      state: {
        required
      },
      city: {
        required
      }
    }
  }
}
</script>

<style scoped>
.max-obd-width {
  max-width: 80% !important;
}
</style>
