  export default {
    props:{
      "current-step": {
        type: Number,
        default: 1
      },
      "max-steps": {
        type: Number,
        default: 1
      },
    },
  }