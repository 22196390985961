import { render, staticRenderFns } from "./DebitForm.vue?vue&type=template&id=1fb9c026&scoped=true&"
import script from "./DebitForm.vue?vue&type=script&lang=js&"
export * from "./DebitForm.vue?vue&type=script&lang=js&"
import style0 from "./DebitForm.vue?vue&type=style&index=0&id=1fb9c026&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb9c026",
  null
  
)

export default component.exports